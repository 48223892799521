/* eslint-disable */
import { Link } from 'react-router-dom';

import Brand from '../Brand/Brand';
import ContactTeam from '../ContactTeam/ContactTeam';
import SvgIconSyncwork from '../Logos/SvgIconSyncwork';
import SvgLogoTechbar from '../Logos/SvgLogoTechbar';

// store
import { useAppSelector } from '../../state/hooks';
import { selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { selectSecondaryMenu } from '../../state/store/slices/secondaryMenuSlice';

import './PageFooter.scss';
import DynamicLink from '../DynamicLink/DynamicLink';
import { useTranslation } from 'react-i18next';

/**
 * @return {html}
*/
const PageFooter = ({ team = null }: any) => {
  const { t } = useTranslation();
  const mainMenu = useAppSelector(selectMainMenu);
  const secondaryMenu = useAppSelector(selectSecondaryMenu);

  /*
  useEffect(() => {
    if (!mainMenu) {
      console.log("Does this really happen?");
      mainMenu = secondaryMenu;
    }
  }, [mainMenu, secondaryMenu]);
  */

  if (!mainMenu && !secondaryMenu) {
    return <></>;
  }

  const theme = mainMenu?.theme?.toLowerCase() || 'syncwork';
  const isTechbarTheme = theme === 'techbar';

  return (
    <footer id="footer" className={`sw-page-footer sw-page-footer-${theme} ${team ? 'color-bg-alabaster-default' : ''}`.trim()}>
      {
        team && (
          <ContactTeam team={team} />
        )
      }
      <div className="polygon">
        <div className="content-container content-container-footer">

          <div className="above-legal">
            <div>
              <div className="logos">
                <div>
                  {
                    (isTechbarTheme) ? (
                      <Link to="/">
                        <Brand
                          iconComponent={(
                            <SvgLogoTechbar
                              color="#fff"
                              height="1.2rem"
                            />
                          )}
                          suffix={null}
                        />
                      </Link>
                    ) : (
                      <Link to="/">
                        <Brand
                          iconComponent={(
                            <SvgIconSyncwork
                              color="#fff"
                              height="2rem"
                            />
                          )}
                          suffix={(mainMenu?.subDomainKey !== 'www') ? mainMenu?.title : null}
                        />
                      </Link>
                    )
                  }

                </div>
                </div>

            {
              (!isTechbarTheme) && (
                <div className="menu">
                  {
                    mainMenu?.menuItems?.map((menuItem) => {
                      // console.log(menuItem);
                      return (
                        <div key={menuItem.id}>
                          {menuItem.pages.length === 1 && (
                            <Link key={menuItem.pages[0].id} to={menuItem.pages[0].slug}>{menuItem.pages[0].title}</Link>
                          )}

                          {menuItem.pages.length > 1 && (
                            <>
                              <div>
                                {menuItem.title}
                              </div>
                              <ul className="list-unstyled">
                                {
                                  menuItem.pages.map(page => (
                                    <li key={page.id}>
                                      <DynamicLink to={`/${page.slug}`}>{page.title}</DynamicLink>
                                    </li>
                                  ))
                                }
                              </ul>
                            </>
                          )}
                        </div>
                      );
                    })
                  }
                </div>
              )
            }
            </div>

            <div className="commendation">
              <div>
                  <div className="top-company">
                    <img src="/images/kununu-logo-white-2025.png" />
                    <div>
                      <span>Wir sind<br />Top-Arbeitgeber</span>
                    </div>
                  </div>
                  <div className="iso">
                    <img src="/images/ecovadis_2024.png" className='ecovadis' />
                    <img src="/images/iso-27001.png" />
                    <img src="/images/iso-9001-2015.png" />
                  </div>
              </div>
            </div>
          </div>

          <div className="legal">
            <div>{t('footer.copyright')} • © {new Date().getFullYear()}</div>
            <div className="legal-links">
              {
                mainMenu?.legalNoticePage && (<Link to={`/${mainMenu.legalNoticePage.slug}`}>{mainMenu.legalNoticePage.menuLabel}</Link>)
              }
              {
                mainMenu?.dataProtectionPage && (<Link to={`/${mainMenu.dataProtectionPage.slug}`}>{mainMenu.dataProtectionPage.menuLabel}</Link>)
              }
            </div>
          </div> {/* legal */}

        </div> {/* content-container content-container-footer */}
      </div> {/* polygon */}
    </footer>
  );
};

export default PageFooter;
